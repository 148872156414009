.img-fluid {
    max-width: 100%;
    height: auto;
    min-height: 100%;
    aspect-ratio: 2.4/2;

    @supports (-moz-appearance:none) {
        height: 100%;
    }
}

.img-fluid:hover {
    cursor: pointer;
}

// Swiper styles

.swiper-pagination-bullet-active {
    background: $gray-200 !important;
}


/* CSS Grid Solution: */
.images--grid {
    display: grid;
    width: 100%;
    grid-template-rows: repeat(2, 1fr);
    grid-template-columns: repeat(2, 1fr);
    grid-auto-flow: dense;
    gap: .5em;
}

/* Photoswipe styles */

.pswp__bg {
    opacity: 1 !important;
}