.btn {
  padding: 0 20px;
  height: 40px;
  line-height: 39px;
  border-radius: 3px;
  transition: 0.4s ease-in-out;
  color: white;
  border-radius: 0;
  
}

.btn-primary {
  background-color: $color-primary;
  border-color: $color-primary;
  &:hover {
    background-color: darken($color-primary, 10%);
    border-color: $color-primary;
  }
}