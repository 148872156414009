body {
  overflow-x: hidden;
  @include body-font;
}

h1,
h2,
h3,
h4, 
h5,
h6 {
  letter-spacing: 0.01rem;
}

a {
  color: #000;

  &:hover {
    color: darken($color-primary, 10%);
  }
}

#places_of_interest {
  ul li {
    margin-bottom: 1rem;
  }
}


#gallary-1,
#gallary-2 {
  background-color: lighten($color-secundary, 10%);
  background-image: linear-gradient(lighten($color-secundary, 10%), lighten($color-secundary, 14%));
  /*background-image: url(../assets/images/istockphoto-1465053603-1024x1024.jpg);*/
  background: linear-gradient(rgba(246, 241, 229, 0.4), rgba(255, 255, 255, 0.7)), url("../assets/images/b6.jpeg?as=webp") scroll center center;
  
  /* Center and scale the image nicely */
  
  background-repeat: no-repeat;
  -webkit-background-size: cover;
  -moz-background-size: cover;
  -o-background-size: cover;
  background-size: cover;
  background-position: bottom;
  /*max-width: 100%;
  height: auto;
  min-height: 50%;*/
  
}

#gallary-2 {
  padding-top: 2rem !important;
  padding-bottom: 2rem !important;
}

#gallary-1 {
  h1 {
    font-size: 1.5rem;
  }
}

#gallary-2 {
  h1 {
    font-size: 3.2rem;
  }
}

#banner {
  h1 {
   font-size: 3.0rem;
  }
}

.cta {
  background: linear-gradient(rgba(2, 2, 2, 0.5), rgba(0, 0, 0, 0.5)), url("../assets/images/23-y-12.jpg?as=webp") scroll center center;
  background-size: cover;
  background-position: bottom;
  padding-top: 12rem !important;
  padding-bottom: 12rem !important;
}

.contact {
  background-color: #dad4b6;
}

.bg-gray {
  background-color: $gray-100;
}

.ff-normal {
  @include body-font;
}

.responsive-iframe {
  width: 100%;

}
