.card {
    &.card-price-1 {
        border-color: #da6149 !important;
    }
    &.card-price-2 {
        border-color: #caad01 !important;
    }
}

#prices {
    .card {
        border-top-width: 4px !important;

        .fw-normal {
            font-weight: 500 !important;
        }
    }
}